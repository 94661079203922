import { UseFormReturnType, useForm } from '@mantine/form'
import { Stack, skipIfOtherField, validateWith } from '@shared/components'
import { Appointment, ConsultationVisitNoteContent } from '@shared/types'
import { CITY_STATE_OTHER, CITY_STATE_UNKNOWN, toTime } from '@shared/utils'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import { useCallback, useEffect } from 'react'
import { isRequired } from '../../../../utils/formValidation'
import { useEmrQuery } from '../../../../utils/hooks'
import { usePatient } from '../../PPatientContext'
import { usePatientVisitNote } from './PatientVisitNoteContext'
import { FullReenrollmentCallVisitNoteFormContent } from './ReenrollmentCallVisitNote'
import { PatientVisitNoteUtilityBar } from './visitNoteForm/PatientVisitNoteUtilityBar'
import { BarriersToTreatmentSection } from './visitNoteSections/BarriersToTreatmentSection'
import { CareTransferReasonForWelcomeCallSection } from './visitNoteSections/CareTransferReasonForWelcomeCallSection'
import { InsuranceSection } from './visitNoteSections/InsuranceSection'
import { LegacyPatientStorySection } from './visitNoteSections/LegacyPatientStorySection'
import { MedicalHistorySection } from './visitNoteSections/MedicalHistorySection'
import { MotivationsForTreatmentSection } from './visitNoteSections/MotivationsForTreatmentSection'
import { ReferralSection } from './visitNoteSections/ReferralSection'
import { ZoomFamiliaritySection } from './visitNoteSections/ZoomFamiliaritySection'
import { MAIN_CONTENT_NOTE_WIDTH, SIDEBAR_NOTE_WIDTH } from './visitNoteUtils'

export type FullWelcomeCallVisitNoteFormContent = ConsultationVisitNoteContent &
  Partial<Record<`${keyof ConsultationVisitNoteContent}_not_discussed`, boolean>>

export type WelcomeCallVisitNoteForm = UseFormReturnType<
  FullWelcomeCallVisitNoteFormContent,
  (values: FullWelcomeCallVisitNoteFormContent) => FullWelcomeCallVisitNoteFormContent
>

export type FullOnboardingCallVisitNoteFormContent =
  | FullReenrollmentCallVisitNoteFormContent
  | FullWelcomeCallVisitNoteFormContent

export type OnboardingVisitNoteForm = UseFormReturnType<
  FullOnboardingCallVisitNoteFormContent,
  (values: FullOnboardingCallVisitNoteFormContent) => FullOnboardingCallVisitNoteFormContent
>
export const WelcomeCallVisitNote = ({ appointmentId }: { appointmentId: Appointment['oid'] }) => {
  const { patientQuery } = usePatient()
  const patientId = patientQuery?.data?.oid

  const appointmentQuery = useEmrQuery(
    'GET /patient/:patientId/appointments/:appointmentId',
    {
      params: { appointmentId, patientId: patientId as string },
    },
    {
      enabled: Boolean(patientId && appointmentId),
    },
  )

  const appointment = appointmentQuery?.data

  const isCareTransfer = appointment?.metadata?.isCareTransfer

  const { setFormRef, visitNoteQuery, saveVisitNote, isSidebar } =
    usePatientVisitNote<ConsultationVisitNoteContent>()

  const workflowSessionResponses = useEmrQuery(
    'GET /workflow/session/:sessionId',
    {
      params: { sessionId: appointment?.metadata?.workflowSessionId as string },
      query: { patientId: patientId as string },
    },
    {
      enabled: Boolean(appointment?.metadata?.workflowSessionId) && Boolean(patientId),
    },
  )

  const choseWelcomeCall = workflowSessionResponses?.data?.promptsAndResponses?.find(
    ({ response }) => response?.payload?.value?.contextKey === 'get_started_with_welcome_call',
  )

  const form = useForm<FullWelcomeCallVisitNoteFormContent>({
    initialValues:
      (visitNoteQuery?.data?.content as FullWelcomeCallVisitNoteFormContent) || undefined,
    validate: {
      motivations_for_treatment_more_details: validateWith(
        skipIfOtherField('motivations_for_treatment_more_details_not_discussed', 'is', true),
        isRequired,
      ),
      was_referred: validateWith(isRequired),
      referral: validateWith(skipIfOtherField('referral_not_discussed', 'is', true), isRequired),
      referralPartnerId: validateWith(skipIfOtherField('referral', 'not', 'provider'), isRequired),
      referral_state: validateWith(skipIfOtherField('referral', 'not', 'provider'), isRequired),
      referral_city: validateWith(
        skipIfOtherField('referral', 'not', 'provider'),
        skipIfOtherField('referral_state', 'is', CITY_STATE_OTHER),
        skipIfOtherField('referral_state', 'is', CITY_STATE_UNKNOWN),
        isRequired,
      ),
      has_insurance: validateWith(
        skipIfOtherField('has_insurance_not_discussed', 'is', true),
        isRequired,
      ),
      insurance_provider: validateWith(
        skipIfOtherField('has_insurance_not_discussed', 'is', true),
        skipIfOtherField('has_insurance', 'is', 'no'),
        isRequired,
      ),
      insurance_determination: validateWith(
        skipIfOtherField('has_insurance_not_discussed', 'is', true),
        skipIfOtherField('has_insurance', 'is', 'no'),
        isRequired,
      ),
      insurance_payment_method: validateWith(
        skipIfOtherField('has_insurance_not_discussed', 'is', true),
        skipIfOtherField('has_insurance', 'is', 'no'),
        isRequired,
      ),
      past_buprenorphone_exposure: validateWith(
        skipIfOtherField('past_buprenorphone_exposure_not_discussed', 'is', true),
        isRequired,
      ),
      zoom_familiarity: validateWith(
        skipIfOtherField('zoom_familiarity_not_discussed', 'is', true),
        isRequired,
      ),
      primary_barrier_to_treatment: validateWith(isRequired),
    },
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaveVisitNote = useCallback(
    debounce(() => {
      void saveVisitNote?.()
    }, toTime('1 sec').ms()),
    [saveVisitNote],
  )

  useEffect(() => {
    if (!isEmpty(form.values)) {
      void debouncedSaveVisitNote()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values])

  setFormRef?.(form)

  return (
    <Stack
      spacing='lg'
      py='md'
      pr='md'
      w={isSidebar ? SIDEBAR_NOTE_WIDTH : MAIN_CONTENT_NOTE_WIDTH}
    >
      <PatientVisitNoteUtilityBar />
      {isCareTransfer && choseWelcomeCall && (
        <CareTransferReasonForWelcomeCallSection form={form} isCareTransfer={isCareTransfer} />
      )}
      <MotivationsForTreatmentSection form={form} />
      <ReferralSection form={form} />
      <LegacyPatientStorySection form={form} />
      <InsuranceSection form={form} />
      <MedicalHistorySection form={form} />
      <ZoomFamiliaritySection form={form} />
      <BarriersToTreatmentSection form={form} />
      <PatientVisitNoteUtilityBar />
    </Stack>
  )
}
