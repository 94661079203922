import {
  AlertIcon,
  EditIcon,
  Group,
  Loader,
  SecondaryButton,
  Stack,
  Table,
  Td,
  Text,
  Th,
} from '@shared/components'
import orderBy from 'lodash/orderBy'
import { useLunaQuery } from '../../../utils/hooks/use-luna-query'
import { PayerEnrollmentListItem } from './BillingInsuranceMatchingV2'

const COLS = {
  payerName: 'QGenda payer name',
  status: 'Status',
  state: 'State',
  planType: 'Plan type',
  eligiblePayerIds: 'Eligible payer IDs',
  candidPayerIds: 'Candid payer IDs',
  cliniciansEnrolled: 'Clinicians enrolled',
  edit: '',
}

export const PayerPlanTypeMap = {
  commercial: 'COM',
  medicaid: 'MCD',
  medicaid_opr: 'MCD-OPR',
  medicare: 'MCR',
  dual_eligible: 'DUAL',
  chip: 'CHIP',
  exchange: 'EXCH',
  d_snp: 'DSNP',
  other: 'OTHER',
} as const

export const PayerStatusMap = {
  in_network: 'INN',
  out_of_network: 'OON',
  inactive: 'Inactive',
} as const

const TableHeader = () => {
  return (
    <thead className='mantine'>
      <tr className='mantine'>
        <Th sortable={false}>{COLS.payerName}</Th>
        <Th sortable={false}>{COLS.status}</Th>
        <Th sortable={false}>{COLS.state}</Th>
        <Th sortable={false}>{COLS.planType}</Th>
        <Th sortable={false}>{COLS.eligiblePayerIds}</Th>
        <Th sortable={false}>{COLS.candidPayerIds}</Th>
        <Th sortable={false}>{COLS.cliniciansEnrolled}</Th>
        <Th sortable={false} style={{ width: '1ch' }}>
          {COLS.edit}
        </Th>
      </tr>
    </thead>
  )
}

const NoData = () => {
  return (
    <Group spacing='xs'>
      <AlertIcon color={c => c.text[1]} />
      <Text color={c => c.text[1]}>No data</Text>
    </Group>
  )
}

export const PayerEnrollmentsTable = (props: {
  setPayer: (payer: PayerEnrollmentListItem | null) => void
}) => {
  const payerEnrollmentsQuery = useLunaQuery('GET /payer-enrollments', {})
  const payerEnrollments = orderBy(payerEnrollmentsQuery.data?.data || [], d => d.payerName, 'asc')

  if (payerEnrollmentsQuery.isLoading) {
    return (
      <Stack p='xl' align='center'>
        <Loader />
      </Stack>
    )
  }

  return (
    <Stack p='lg'>
      <Table withBorder striped>
        <TableHeader />
        <tbody className='mantine'>
          {payerEnrollments.map(payer => (
            <tr key={payer.oid} className='mantine'>
              <Td>
                <Text>{payer.payerName}</Text>
              </Td>
              <Td>
                <Text>{PayerStatusMap[payer.status]}</Text>
              </Td>
              <Td>
                <Text>{payer.states.join(', ')}</Text>
              </Td>
              <Td>
                <Text>
                  {payer.lineOfBusiness
                    .map(lineOfBusiness => PayerPlanTypeMap[lineOfBusiness])
                    .join(', ')}
                </Text>
              </Td>
              <Td>
                <Text>{payer.eligiblePayerIds.filter(Boolean).join(', ') || <NoData />}</Text>
              </Td>
              <Td>
                <Text>{payer.candidPayerIds.filter(Boolean).join(', ') || <NoData />}</Text>
              </Td>
              <Td>
                <Text>{payer.enrollments.length}</Text>
              </Td>
              <Td>
                <SecondaryButton
                  size='xs'
                  leftIcon={<EditIcon />}
                  onClick={() => {
                    props.setPayer(payer)
                  }}
                />
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Stack>
  )
}
