import { CORRECTIONAL_FACILITY_PHONE, Patient } from '@shared/types'
import { useQuery } from 'react-query'
import { emrApi } from '../../api'

export const useIdentifyDuplicatePatients = ({ patient }: { patient?: Patient }) => {
  const phoneDuplicatesQuery = useQuery(
    ...emrApi.getQuery('GET /patients', {
      query: { phone: patient?.personalData?.phone },
    }),
    {
      enabled: Boolean(patient?.personalData?.phone),
    },
  )

  const phoneDuplicates =
    phoneDuplicatesQuery.data?.items.filter(
      // Filter out the temporary correctional facility phone number
      pt => pt?.oid !== patient?.oid && pt?.personalData?.phone !== CORRECTIONAL_FACILITY_PHONE,
    ) || []

  const emailDuplicatesQuery = useQuery(
    ...emrApi.getQuery('GET /patients', {
      query: { email: patient?.personalData?.email },
    }),
    {
      enabled: Boolean(patient?.personalData?.email),
    },
  )

  const emailDuplicates =
    emailDuplicatesQuery.data?.items.filter(pt => pt?.oid !== patient?.oid) || []

  const allDuplicates = Array.from(new Set([...phoneDuplicates, ...emailDuplicates]))

  return {
    phoneDuplicates,
    emailDuplicates,
    allDuplicates,
    numDuplicates: allDuplicates.length,
    hasDuplicates: allDuplicates.length > 0,
  }
}
