import { RadioGroup, Stack } from '@shared/components'
import { HDYHAUOptions, ReferralOption, states } from '@shared/types'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { emrApi } from '../../../../../api'
import ALoadingSpinner from '../../../../../components/atoms/ALoadingSpinner'
import { useLunaQuery } from '../../../../../utils/hooks'
import { usePatient } from '../../../PPatientContext'
import { WelcomeCallVisitNoteForm } from '../WelcomeCallVisitNote'
import { PatientVisitNoteCityStateSelectGroup } from '../visitNoteForm/PatientVisitNoteCityStateSelectGroup'
import { PatientVisitNoteDefaultTextMultiline } from '../visitNoteForm/PatientVisitNoteDefaultTextMultiline'
import { PatientVisitNoteNotDiscussedCheckbox } from '../visitNoteForm/PatientVisitNoteNotDiscussedCheckbox'
import { PatientVisitNoteRadio } from '../visitNoteForm/PatientVisitNoteRadio'
import { PatientVisitNoteSection } from '../visitNoteForm/PatientVisitNoteSection'
import { PatientVisitNoteSelect } from '../visitNoteForm/PatientVisitNoteSelect'
import { PatientVisitNoteTextInput } from '../visitNoteForm/PatientVisitNoteTextInput'

export const ReferralSection = ({ form }: { form: WelcomeCallVisitNoteForm }) => {
  const referralPartnersQuery = useQuery(...emrApi.getQuery('GET /referral-partners'))
  const referralPartners = referralPartnersQuery?.data || []

  const referralPartnersOptions = [
    ...referralPartners.map(rp => ({ value: rp.oid, label: rp.name })),
    'N/A',
  ]
  const { patientId } = usePatient()

  const referralQuery = useLunaQuery('GET /workflow-prompt-responses/:patientId/value/:value', {
    params: { patientId, value: 'who_referred_you' },
  })

  const whoReferredYouResponse = referralQuery?.data?.data

  const referral = whoReferredYouResponse?.who_referred_you
  const referralCity = whoReferredYouResponse?.who_referred_you_city
  const referralState = whoReferredYouResponse?.who_referred_you_state

  const referralResponses = [
    referral && `Referrer: ${referral}`,
    referralCity && `City: ${referralCity}`,
    referralState && `State: ${referralState}`,
  ].filter(Boolean) as string[]

  useEffect(() => {
    /*
     * This checks that the referral partner id is a valid option
     * in case a referral partner is selected and then the user changes the referral type
     */
    if (
      form.values.referralPartnerId &&
      form.values.referralPartnerId !== 'N/A' &&
      !referralPartnersOptions.some(
        option => typeof option !== 'string' && option?.value === form.values.referralPartnerId,
      )
    ) {
      form.setFieldValue('referralPartnerId', '')
    }
  }, [form.values.referralPartnerId, referralPartnersOptions, form])

  useEffect(() => {
    /*
     * This checks that the referral partner id is a valid option
     * in case a referral partner is selected and then the user changes the referral type
     */
    if (form.values.was_referred === 'yes') {
      form.setFieldValue('referral', 'provider')
    } else if (form.values.referral === 'provider') {
      // If patient was not referred, then set referral to empty string
      form.setFieldValue('referral', undefined)
    }
  }, [form.values.was_referred, form.values.referral])

  const AvailableHYDHAUOptions =
    form.values.was_referred === 'yes'
      ? HDYHAUOptions
      : HDYHAUOptions.filter(
          (option: { value: ReferralOption; label: string }) => option.value !== 'provider',
        )

  return (
    <PatientVisitNoteSection title='Referral'>
      {referralQuery.isLoading && <ALoadingSpinner />}
      {referralResponses.length > 0 && (
        <Stack spacing='xs'>
          <PatientVisitNoteDefaultTextMultiline
            label='Patient response'
            values={referralResponses}
          />
        </Stack>
      )}
      <RadioGroup
        label='Was the patient referred to Ophelia by someone at another organization (e.g., healthcare provider, substance use treatment facility, social services organization, etc.)?'
        orientation='horizontal'
        {...form?.getInputProps('was_referred')}
      >
        <PatientVisitNoteRadio value='yes' label='Yes' />
        <PatientVisitNoteRadio value='no' label='No' />
      </RadioGroup>
      {form.values.was_referred && ['yes', 'no'].includes(form.values.was_referred) && (
        <PatientVisitNoteSelect
          label='How did the patient hear about us?'
          placeholder='Select one...'
          data={AvailableHYDHAUOptions}
          {...form?.getInputProps('referral')}
          readOnly={form.values.was_referred === 'yes'}
        />
      )}
      {form.values.was_referred === 'yes' && (
        <>
          <PatientVisitNoteSelect
            label='Official Ophelia referral partner?'
            placeholder='Select one...'
            data={referralPartnersOptions}
            {...form?.getInputProps('referralPartnerId')}
          />
          <PatientVisitNoteTextInput
            label='Provider name, if not official referral partner (optional)'
            placeholder='E.g. Example Medical Center'
            {...form?.getInputProps('organization')}
          />
          <PatientVisitNoteTextInput
            label='Individual at the organization (optional)'
            placeholder='E.g. Dr. John Doe'
            {...form?.getInputProps('provider')}
          />
          {form.values.referral === 'provider' && (
            <PatientVisitNoteCityStateSelectGroup
              states={states}
              inputProps={form.getInputProps}
              cityPropName='referral_city'
              statePropName='referral_state'
            />
          )}
        </>
      )}
      {Boolean(form?.values.referral) && (
        <PatientVisitNoteTextInput
          placeholder='Share any helpful info...'
          {...form?.getInputProps('referral_additional_details')}
        />
      )}
      <PatientVisitNoteNotDiscussedCheckbox field='referral' form={form} />
    </PatientVisitNoteSection>
  )
}
