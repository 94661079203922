import { Employee, hasRole } from '@shared/types'
import { useCallback } from 'react'
import { UseFormRegister } from 'react-hook-form-latest'
import AOption from '../../components/atoms/AOption'
import ASelect from '../../components/atoms/ASelect'
import { IconVerifiable } from '../../components/icons/IconVerifiable'
import ODSYesNoRadioList from '../../components/ods/ODSYesNoRadioList'
import { useEmployees, useFlags } from '../../utils/hooks'
import AProfileSection, { AProfileItem, AProfileItemEdit } from './AProfileSection'
import { IntakeStatesMultiSelect } from './IntakeStatesMultiSelect'

const fieldToLabel = {
  nonOpheliaPatients: 'Non-Ophelia patients',
  prescribingCap: 'Prescribing cap',
  doseSpotId: 'DoseSpot Id',
  availableForScheduling: 'Accepting patients',
  spc: 'Supervising clinician',
  availableForCheckins: 'Taking check-in calls',
} as Record<keyof Employee<'clinician'>, string>

export type MEmployeeProviderTabProps = {
  employee: Employee<'clinician'>
  editMode: boolean
  register: UseFormRegister<Employee>
  refetchEmployee: () => void
}

const MEmployeeProviderTab = ({
  employee,
  editMode,
  register,
  refetchEmployee,
}: MEmployeeProviderTabProps) => {
  const { qgendaMigration } = useFlags()

  const displayEmployeeField = useCallback(
    (employee: Employee<'clinician'>, field: keyof Employee<'clinician'>) => {
      if (!employee[field]) {
        return
      }

      return employee[field]
    },
    [],
  )

  const NADEA = employee.providerInfo?.nadea ?? ''

  const employeesQuery = useEmployees({ role: ['spc', 'sncm', 'stn'] })
  const employees = employeesQuery.data || []
  const spc = employee.spc ? employees.find(e => e.oid === employee.spc)?.name : 'N/A'

  return (
    <>
      <AProfileSection title='Ophelia'>
        {(
          ['nonOpheliaPatients', 'prescribingCap', 'doseSpotId'] as (keyof Employee<'clinician'>)[]
        ).map(field => {
          const value = displayEmployeeField(employee, field) as string
          return (
            <AProfileItem
              name={fieldToLabel[field]}
              value={value}
              displayEdit={editMode}
              key={field}
            >
              <AProfileItemEdit type='text' register={register} field={field} />
            </AProfileItem>
          )
        })}
        <AProfileItem
          name={fieldToLabel.availableForScheduling}
          value={employee.availableForScheduling === 'closed' ? 'Not available' : 'Available'}
          displayEdit={editMode}
          key='availableForScheduling'
        >
          <ODSYesNoRadioList
            direction='horizontal'
            yesValue='open'
            noValue='closed'
            {...register('availableForScheduling')}
          />
        </AProfileItem>
        {!hasRole(employee, 'spc') && (
          <AProfileItem name={fieldToLabel.spc} value={spc} displayEdit={editMode} key='sc'>
            <ASelect {...register('spc')}>
              <AOption key='default' value=''>
                N/A
              </AOption>
              {employees.map(({ name, oid }) => (
                <AOption key={oid} value={oid}>
                  {name}
                </AOption>
              ))}
            </ASelect>
          </AProfileItem>
        )}
        {hasRole(employee, 'stn', 'tn', 'ncm_tn') && (
          <AProfileItem
            name={fieldToLabel.availableForCheckins}
            value={employee.availableForCheckins === 'closed' ? 'Not available' : 'Available'}
            displayEdit={editMode}
            key='availableForCheckins'
          >
            <ODSYesNoRadioList
              direction='horizontal'
              yesValue='open'
              noValue='closed'
              {...register('availableForCheckins')}
            />
          </AProfileItem>
        )}
        <IntakeStatesMultiSelect
          employeeId={employee.oid}
          isEditing={editMode}
          refetchEmployee={refetchEmployee}
        />
      </AProfileSection>
      {/* NOTE: the Verifiable section is deprecated and will be removed in the future. The same
      information is now available in the QGenda section below. Will continue to show both sections
      until we are confident that all necessary information has been migrated to QGenda. */}
      <AProfileSection
        title={<IconVerifiable />}
        content={
          editMode ? (
            <div className='mb-2'>
              This information can only be edited through Verifiable. Changes may take up to an hour
              to appear here.
            </div>
          ) : undefined
        }
      >
        <AProfileItem name='NPI #' value={employee.providerInfo?.npi?.toString()} />
        {/* TODO: deaRegistrationNumbers is deprecated, use deaRegistrations */}
        <AProfileItem
          name='DEA #'
          value={employee.providerInfo?.deaRegistrationNumbers?.join(', ')}
        />
        <AProfileItem name='NADEA #' value={NADEA.toString()} />
        <AProfileItem
          name='Active states | License numbers'
          value={
            employee.providerInfo?.licenses
              ?.filter(license => license.isCurrentlyPracticing)
              ?.map(license => `${license.state} | ${license.licenseNumber}`)
              ?.join(', ') || 'No Active States.'
          }
        />
        <AProfileItem name='Provider type' value={employee.providerInfo?.providerType} />
      </AProfileSection>
      {qgendaMigration && (
        <AProfileSection
          title='QGenda'
          content={
            editMode ? (
              <div className='mb-2'>This information can only be edited through QGenda.</div>
            ) : undefined
          }
        >
          <AProfileItem name='NPI #' value={employee?.provider?.npi} />
          <AProfileItem name='Provider Type' value={employee?.provider?.providerType} />
          <AProfileItem
            name='DEA #'
            value={employee.provider?.deaLicenses
              ?.map(license => license?.licenseNumber)
              ?.join(', ')}
          />
          <AProfileItem
            name='Active states | License numbers'
            value={
              employee.provider?.stateLicenses
                ?.filter(license => license?.currentlyPracticing)
                ?.map(license => `${license?.state} | ${license?.licenseNumber}`)
                ?.join(', ') || 'No Active States.'
            }
          />
        </AProfileSection>
      )}
    </>
  )
}

export default MEmployeeProviderTab
